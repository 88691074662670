<template>
  <v-col cols="12" class="pa-0 system_alert" v-if="warningNote">
    <v-slide-y-reverse-transition>
      <v-alert  dense prominent class="border-0 ma-0 subtitle-1 font-weight-bold shadow" large type="error"  :color="warningColor">
        <span class="white--text"> {{ warningNote }}</span>
      </v-alert>
    </v-slide-y-reverse-transition>
  </v-col>
</template>


<script>
export default {
  name: "SystemAlert",
  props: {},
  data: () => ({

  }),
  computed: {
    warningNote() {
      return this.$store.state.userData?.warning_note
    },
    warningColor() {
      return this.$store.state.userData?.warning_color || 'warning'
    },
  },
  watch: {

  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {

  },
};
</script>


<style scoped lang="scss">
.system_alert {
  position: relative;
  z-index: 1000;
  width: -webkit-fill-available;
  top:  10px;
  // left: 0;
  // right: 0;

  .v-alert {
    border-radius: 0 !important;
  }
}
</style>
