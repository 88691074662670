

import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapGetters } = createNamespacedHelpers("InvoiceStore");
export const SalesQTYMixins = {
  data: () => ({
    isEdit: false,
    invoiceKey: 1,
    goldKey: 1,
    loading: false,
    descriptionDialog: false,
    createBill: false,
    goldCaliber: null,
    toggle_caliber: 18,
    salesForm: false,
    isLoading: true,
    subCategories: [],
    categoryID: "",
    gold_price: {},
    vats: {},
    qualities: [],
    shapes: [],
    clarities: [],
    colors: [],
    sales_agents: [],
    stones: [],
    machines: [],
    categories: [],
    customer: Object,
    cart_type: 3,
    supplier: {
      id: 0,
      name: null,
    },
    salesAgent: "",
    machineId: 0,
    searchInput: null,
    invoice_date: null,
    description: null,
    payments: [],
    totalPaid: 0,
    form: [],
    coupons: [],
    printID: 0,
    showPrint: false,
    has_whatsapp: false,
    printContent: {},
    printLoading: false,
    usedModal: false,
    printURL: 'export_invoice_sales_qty',
    stock_type: null,
    stock_watch: 0,
    printDialog: false,
    print_templates: [],
    invoice_id: null,
    is_special: 0,
    sales_note: 0,
    selected_deposit: {
      id: 0,
      amount: 0,
    },
    withdrawalsDialog: {
      dialog: false,
      customer_id: null,
      supplier_id: null,
    },
    cost_identity_details : [],
    costIdentityLoading : false,
    userLocation: {
      latitude: null,
      longitude: null,
    },
  }),

  computed: {
    ...mapState(["bill", "totalPieces", 'totalPrice', 'discountDialog', 'priceWithoutDiscount', 'totalDiscount', "customerType", "priceWithoutVat", "totalVat", "goldType", "isTypesLoading", "discount"]),
    ...mapGetters(["totalPriceWithDiscount"]),

    filteredSubCategories() {
      let filterByCategory = this.subCategories.filter(category => category.category_id == this.goldCaliber)
      if (this.searchInput) {
        const lowerCaseSearchInput = this.searchInput.toLowerCase();
        return filterByCategory.filter(({ name_ar, name_en }) => String(name_ar).toLowerCase().startsWith(lowerCaseSearchInput) || String(name_en).toLowerCase().startsWith(lowerCaseSearchInput));
      } else {
        return filterByCategory
      }
    },
    valid() {
      if (this.customerType == 1) {
        if (this.salesAgent && this.customer.id && Math.round(this.totalPrice) <= Math.round(this.totalPaid) && this.bill.length !== 0) {
          return true
        } else {
          return false
        }
      }
      else {
        if (this.salesAgent && this.supplier.id && this.bill.length !== 0) {
          return true
        } else {
          return false
        }
      }
    },
    categoryTitle() {
      switch (+this.goldCaliber) {
        case 18:
        case 21:
        case 22:
        case 24: return this.$t('items') + ' ' + this.$t('caliber') + ' ' + this.goldCaliber
        case 25: return this.$t('diamond items')
        case 26: return this.$t('stone items')
        case 50: return this.$t('services')
        case 925: return this.$t('silver items')
        default: return ''
      }
    }
  },
  destroyed: function () {
    this.clearBill().finally(() => { });
  },

  mounted() {
    this.$global.GET_USER_LOCATION().then((location) => { this.userLocation = location })
    this.changeCustomerType(1);
    if (!this.$global.CheckAction("06-001", 1)) {
      this.$router.push('/login')
    }
    else {
      this.getData();
    }
    window.addEventListener("keydown", e => {
      if (e.altKey == true && e.code == "KeyU") {
        this.usedModal = true;
      }
      if (e.code == 'Escape') {
        this.usedModal = false;
      }
    });

  },
  watch: {
    customerType() {
      if (this.customerType == 2) {
        this.customer = Object;
      }
      this.selected_deposit = {
        id: 0,
        amount: 0,
      }
    },
    'customer.id'() {
      this.selected_deposit = {
        id: 0,
        amount: 0,
      }
    },
    bill() {
      this.selected_deposit = {
        id: 0,
        amount: 0,
      }
      this.payments = [];
      this.totalPaid = this.payments.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.amount);
      }, 0);
    },

  },
  methods: {
    ...mapActions(["addBillAction", "clearBill", "toggleDiscountDialog", "changeCustomerType", "setDiscount"]),
    getData() {
      this.isLoading = true;
      this.description = null;
      var branch_ID = localStorage.getItem('currentbranch')
      this.$api.GET_METHOD(`get_create_invoice_data_by_qty?branch_id=${branch_ID || ''}`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          // this.goldCaliber = response.data.categorys[0].id
          this.gold_price = response.data.gold_price;
          this.subCategories = response.data.items;
          this.vats = response.data.vats;
          this.$store.commit('InvoiceStore/DEFAULT_DATA', response.data)
          this.sales_agents = response.data.sales_agents;
          this.categories = response.data.categorys;
          response.data.colors.push({ color_id: 0, color_code: '-' })
          response.data.claritys.push({ clarity_id: 0, clarity_code: '-' })
          response.data.shaps.push({ id: 0, title: '-' })
          response.data.qualitys.push({ id: 0, title: '-' })
          this.colors = response.data.colors;
          this.clarities = response.data.claritys;
          this.stones = response.data.stones;
          this.shapes = response.data.shaps;
          this.sales_note = response.data.sales_note || 0;
          this.qualities = response.data.qualitys;
          this.machines = response.data.machines;
          this.stock_type = response.data.stock_type;
          this.stock_watch = response.data.stock_watch;
          this.has_whatsapp = response.data.has_whatsapp || false;
          this.customer = response.data.default_customer ? { id: 1, name: "عميل نقدي" } : Object;
          this.print_templates = response.data.prints || []
          if (response.data.sales_agents.length > 0) {
            this.salesAgent = response.data.sales_agents.find(agent => agent.id === response.data.defualt_sales_agent) || {}
          }
          if (response.data.machines.length > 0) {
            this.machineId = response.data.machines[0]
          }
          this.getCostIdentityDetails();
        }
        if (this.$route.params.id && this.$store.state.userData.super_admin == 1) {
          this.isLoading = true;
          this.$api.GET_METHOD(`get_sales_invoice_by_qty/invoice?invoiceCode=${this.$route.params.id}`).then((showResponse) => {
            this.isLoading = false;
            if (showResponse.check) {
              this.invoice_date = showResponse.data.master.invoice_date;
              this.is_special = showResponse.data.master.is_special || 0;
              this.changeCustomerType(showResponse.data.master.customer_type);
              if (showResponse.data.master.customer_type == 1) {
                this.customer = {
                  id: showResponse.data.master.customer_id,
                  name: showResponse.data.master.customer_name,
                }
                this.supplier = {}
              } else {
                this.customer = {};
                this.supplier = {
                  id: showResponse.data.master.supplier_id,
                  name: showResponse.data.master.customer_name,
                }
              }
              this.salesAgent = {
                id: showResponse.data.master.sales_agent_id,
                name: showResponse.data.master.sales_agent_name,
              }
              showResponse.data.details.forEach(detail => {
                let prepareDetail = {
                  d_id: detail.d_id,
                  item_code: detail.item_code,
                  caliber_price: detail.caliber_price,
                  subCategoryId: detail.sub_category_id,
                  caliber_id: detail.caliber_id,
                  itemTitle: detail.sub_category_title,
                  categoryId: detail.category_id,
                  gold_weight: detail.gold_weight,
                  countPieces: detail.count_pieces,
                  minPrice: detail.price,
                  priceWithoutVat: detail.price,
                  vat: detail.total_vat,
                  price: detail.grand_total,
                  lobes: detail.lobes || 0,
                  notes: detail.notes,
                  countPiecesPrice: detail.count_pieces,

                  weight: detail.weight,
                  diamond_weight: detail.diamond_weight,
                  count_diamonds: detail.count_diamonds,
                  quality_id: detail.quality_id,
                  clarity_id: detail.clarity_id,
                  color_id: detail.color_id,
                  shap_id: detail.shap_id,
                  stone_weight: detail.stone_weight,
                }
                this.addBillAction(prepareDetail).finally()
              });
              let payments = []
              showResponse.data.payments.forEach(payment => {
                payments.push({
                  machine_id: payment.machine_id,
                  amount: payment.amount,
                  paymentType: payment.payment_type,
                  cart_type: payment.cart_type || 0,
                  payment_title: '',
                })
              });
              setTimeout(() => {
                this.isEdit = true;
                this.setPayment(payments)
              }, 1);
            }
          })
        }
      })
    },
    setAgent(returnedValue) {
      this.salesAgent = returnedValue;
    },
    resetPayment() {
      this.totalPaid = 0;
      this.payments = [];
      this.selected_deposit = {
        id: 0,
        amount: 0,
      }
      this.invoiceKey = this.invoiceKey + 1

    },
    setMachine(returnedValue) {
      this.machineId = returnedValue;
    },
    setPayment(returnedValue) {
      this.payments = returnedValue;
      this.totalPaid = this.payments.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.amount);
      }, 0);
    },
    setDeposit(returnedValue) {
      const payment = {
        deposit_id: returnedValue.receipt_voucher_id,
        machine_id: 0,
        cart_type: 0,
        amount: returnedValue.amount,
        paymentType: 4,
        payment_title: "عربون",
      }
      this.selected_deposit = payment;
      const getIndex = this.payments.findIndex((row) => row.paymentType == 4)
      if (getIndex >= 0) {
        this.payments[getIndex] = payment
      } else {
        this.payments.push(payment);
      }
      this.totalPrice = +this.totalPrice - +returnedValue.amount;
      this.totalPaid = this.payments.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.amount);
      }, 0);
    },
    setCustomer(returnedValue) {
      if (this.customerType == 2) {
        this.customer = Object;
        this.supplier = returnedValue;

      } else {
        this.customer = returnedValue;
        this.supplier = Object;
      }
    },
    usedModalMethod() {
      this.usedModal = false
    },
    saveInvoice() {
      let requestBody = {
        invoice_date: this.invoice_date,
        salesAgent: this.salesAgent.id,
        machineId: +this.machineId.id,
        customerID: this.supplier.id ? null : this.customer.id,
        supplier_id: this.supplier.id,
        invoiceType: this.customerType,
        description: this.description,
        items: this.bill,
        payments: this.payments,
        cart_type: this.cart_type,
        coupon_id: this.discount?.coupon?.id || 0,
        total_discount: this.totalDiscount || 0,
        is_special: this.is_special || 0,
        ...this.userLocation,
      }
      if (this.bill.length == 0) {
        this.$swal.fire({
          title: this.$i18n.t('Please Check'),
          text: this.$i18n.t('noItemsAdded'),
          icon: "info",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      console.log(this.customer, this.supplier);
      if ((this.customer.id || this.supplier.id) && this.salesAgent.id && this.bill.length > 0) {
        console.log("requestBody", requestBody);
        this.loading = true;
        let end_point = this.isEdit ? `update_sales_invoice/${this.$route.params.id}` : "create_invoice_by_qty";
        let save_message = this.$t("Invoice Saved Successfully");
        this.$api.POST_METHOD(end_point, requestBody, true, save_message).then((response) => {
          this.loading = false;
          console.log('response ===>', response);
          if (response.check) {
            if (this.isEdit) {
              this.$router.push(`/sales`)
            }
            this.customer = {
              id: 0,
              name: "عميل نقدي",
            },
              this.supplier = {
                id: 0,
                name: null,
              },
              this.changeCustomerType(1);
            this.totalPaid = 0;
            this.is_special = 0;
            this.resetPayment();
            this.goldKey = this.goldKey + 1;
            this.cart_type = 3;
            this.goldCaliber = null;
            this.description = null;
            this.clearBill();
            this.getData();
            this.printMethod(response.data)
          }
        })
      }
      else {
        let text = ''
        if (this.customerType == 1) {
          text = this.$t("choose customer")
        }
        if (this.customerType == 2) {
          text = this.$t("choose supplier")
        }
        if (!this.salesAgent.id) {
          text = this.$t("select agent")
        }
        this.$swal.fire({
          title: this.$t('Please Check'),
          text: text,
          icon: "info",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    setActiveCategory(cat) {
      this.goldCaliber = cat
    },
    printMethod(m_id) {
      this.invoice_id = m_id
      this.printDialog = true;
    },
    closePrint() {
      this.printDialog = false;
      this.invoice_id = null
    },
    customerWithdrawals(id) {
      this.withdrawalsDialog = {
        dialog: true,
        customer_id: this.customerType == 1 ? id : null,
        supplier_id: this.customerType == 2 ? id : null,
      };
    },
    closeWithdrawalsDialog() {
      this.withdrawalsDialog = {
        dialog: false,
        customer_id: null,
        supplier_id: null,
      };
    },
    getCostIdentityDetails() {
      this.costIdentityLoading = true;
      this.$api.GET_METHOD(`cost_identity_details`).then((response) => {
        this.costIdentityLoading = false;
        if (response.check) {
          this.cost_identity_details = response?.data.details || [];
        }
      })
    },
  },
};
