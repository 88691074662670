<template>
  <section>
    <!-- Page Is Loading -->
    <v-container fluid v-if="isLoading">
      <SkeletonInvoiceLoader />
    </v-container>
    <!-- Page Loaded -->
    <v-container fluid v-if="!isLoading">
      <v-row>
        <!-- Categories && Sub Categories -->
        <v-col v-if="!salesForm" cols="12" md="6">
          <!-- Sale by Pieces => stock type = 2 -->
          <SalesByPiece :gold_price="gold_price" v-if="stock_type.some(action => action == 2)"
            :sub_categories="subCategories" :vats="vats" :goldCaliber="goldCaliber"
            :searchByName="(value) => searchInput = value" :qualities="qualities" :shapes="shapes"
            :clarities="clarities" :colors="colors" :cost_identity_details="cost_identity_details"
            :getCostIdentityDetails="getCostIdentityDetails" :costIdentityLoading="costIdentityLoading" />
          <!-- Sale by Qty => stock type = 1 -->
          <!-- Categories -->
          <v-col cols="12" class="white shadow rounded-lg pa-5" v-if="stock_type.some(action => action == 1)" sm="12">
            <CategoriesBar :categories="categories" v-if="categories.length > 0" :gold_price="gold_price"
              :activeCategory="goldCaliber" :backValueMethod="setActiveCategory" />
          </v-col>
          <!-- Sub Categories -->
          <v-col cols="12" class="pa-0 my-5" v-if="stock_type.some(action => action == 1)" sm="12">
            <v-card class="white shadow rounded-lg d-sm-flex d-block pa-5" :key="`gold${goldKey}`" v-if="goldCaliber">
              <v-row align="center">
                <v-col cols="6" lg="2" md="3" sm="3" v-for="(category, index) in filteredSubCategories" :key="index"
                  class="paddingX">

                  <!-- If Category = 18 || 21 || 22 || 24 -->
                  <ItemModal v-if="goldCaliber == 18 || goldCaliber == 21 || goldCaliber == 22 || goldCaliber == 24"
                    :sales_note="sales_note" :caliber="goldCaliber" :stock_watch="stock_watch" :vat="vats[goldCaliber]"
                    :goldType="category" :cost_identity_details="cost_identity_details" />

                  <!-- If Category = 25 => Diamond -->
                  <DiamondModal v-else-if="goldCaliber == 25" :stock_watch="stock_watch" :caliber="goldCaliber"
                    :sales_note="sales_note" :vat="vats[goldCaliber]" :vats="vats" :gold_price="gold_price"
                    :goldType="category" :qualities="qualities" :shapes="shapes" :clarities="clarities"
                    :colors="colors" />

                  <!-- If Category >= 26 <= 49 => Stone -->
                  <DefaultItemSalesModal v-else-if="goldCaliber >= 26 && goldCaliber <= 49" :stock_watch="stock_watch"
                    :caliber="goldCaliber" :sales_note="sales_note" :vat="vats[goldCaliber]" :vats="vats"
                    :gold_price="gold_price" :goldType="category" />

                  <!-- If Category = 925 => Silver -->
                  <SilverModal v-else-if="goldCaliber == 925" :stock_watch="stock_watch" :caliber="goldCaliber"
                    :sales_note="sales_note" :vat="vats[goldCaliber]" :vats="vats" :gold_price="gold_price"
                    :goldType="category" :silvers="stones" />

                  <!-- If Category = 50 || or defaults => Services -->
                  <ServicesModal v-else :stock_watch="stock_watch" :caliber="goldCaliber" :vat="vats[goldCaliber]"
                    :sales_note="sales_note" :vats="vats" :goldType="category" />

                </v-col>
                <!-- If No subcategories in active category -->
                <v-col v-if="filteredSubCategories.length == 0 && goldCaliber" class="text-center">
                  <template v-if="searchInput">
                    <span class="blue5--text">"{{ searchInput }}"</span> {{ $t('not found') }}
                  </template>
                  <template v-else>
                    <span v-if="goldCaliber == 26">{{ $t("notAvail") }} {{ $t("services") }} {{ $t("here") }}</span>
                    <span v-else>{{ $t("notAvail") }} {{ $t("items") }} {{ $t("here") }}</span>
                  </template>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-col>

        <!-- Invoice Details -->
        <v-col cols="12" md="6">
          <v-row justify="space-between" align="center">
            <v-col class="mb-lg-5 mt-2 mx-lg-2 pa-0 pa-sm-2" cols="auto">
              <p class="ma-0" v-if="customerType == 1 || customer?.customer_type == 2">
                {{ customer?.customer_type == 2 ? $t("late customer") : $t("cash customer") }} :
                <span v-if="customer.id">{{ customer.name }}</span>
                <span v-else class="text--disabled">{{ $t('no customer selected') }}</span>
                <v-btn rounded-md icon x-small class="mx-1" v-if="customer.id" @click="customerWithdrawals(customer.id)"
                  color="info" depressed>
                  <img height="15" src="@/assets/img/svg/withdrawals.svg" alt="" />
                </v-btn>
              </p>
              <p class="ma-0" v-else-if="customerType == 2">
                {{ $t("late customer") }} :
                <span v-if="supplier.id">{{ supplier.name }}</span>
                <span v-else class="text--disabled">{{ $t('no supplier selected') }}</span>
                <v-btn rounded-md icon x-small class="mx-1" v-if="supplier.id" @click="customerWithdrawals(supplier.id)"
                  color="info" depressed>
                  <img height="15" src="@/assets/img/svg/withdrawals.svg" alt="" />
                </v-btn>
              </p>

            </v-col>
            <v-col class="mb-5 mt-2 mx-2" cols="auto" :key="`customer${invoiceKey}`">
              <div class="d-flex align-center">
                <CustomerModal :backValueMethod="setCustomer" :supplier="supplier" :customer="customer" />
              </div>
            </v-col>
          </v-row>

          <!-- Invoice Table -->
          <div class="mt-2">
            <InvoiceData />
          </div>

          <!-- Payment Type - Machine - Sales Man -->
          <v-col cols="12" class="pa-0 mt-2">
            <v-card class="shadow rounded-lg justify-center pa-2" :key="`payment${invoiceKey}`">
              <v-row justify-lg="space-between">
                <v-col cols="auto" class="ps-3 px-مل-1">
                  <PaymentMethod :backValueMethod="setPayment" :cart_type="cart_type"
                    :setCartType="(type) => { cart_type = type }" :machineID="machineId"
                    :selected_deposit="selected_deposit" />
                </v-col>
                <v-col cols="auto" class="">
                  <BankModal v-if="payments.length ? payments?.some(({ paymentType }) => paymentType == 2) : true"
                    :machines="machines" :machine="machineId" :backValueMethod="setMachine" />
                </v-col>
                <v-col cols="auto" class="">
                  <SellarModal :checkChangeAgent="true" :sales_agents="sales_agents" :sellar="salesAgent"
                    :backValueMethod="setAgent" />
                </v-col>
                <v-col cols="auto" class="">
                  <CustomerDepositModal :total_price="totalPrice" :customer_type="customerType"
                    :customer_id="customer.id" :selected_deposit="selected_deposit" :setDeposit="setDeposit" />
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- Invoice Payments Summery -->
          <v-col cols="12" class="pa-3 mt-2">
            <v-row justify="space-between">
              <v-col cols="auto">
                <h5>{{ $t("finalCash") }}</h5>
                <h5>
                  <strong>{{ $global.DecimalFormatter(Number(totalPrice.toFixed(2)).toFixed(2)) }}</strong>
                  <span v-if="discount.discount_amount"
                    class="mx-2 font-weight-regular gray8--text text-decoration-line-through">
                    {{ priceWithoutDiscount | float }}</span>
                </h5>
              </v-col>
              <v-col cols="auto" v-if="discount.discount_amount">
                <h5 class="text--disabled">{{ $t("total discount") }}</h5>
                <h5 class="error--text">
                  <strong>{{ totalDiscount | float }}</strong>
                  <strong class="mx-2 black--text" v-if="discount.coupon_id">({{ discount.coupon.coupon_code
                    }})</strong>
                </h5>
              </v-col>
              <v-col cols="auto">
                <h5 class="text--disabled">{{ $t("vat") }}</h5>
                <h5>
                  <strong>{{ $global.DecimalFormatter(Number(totalVat.toFixed(2)).toFixed(2)) }}</strong>
                </h5>
              </v-col>
              <v-col cols="auto">
                <h5 class="text--disabled">{{ $t("totalPaid") }}</h5>
                <h5>
                  <strong>{{ $global.DecimalFormatter(Number(totalPaid.toFixed(2)).toFixed(2)) }}</strong>
                </h5>
              </v-col>
              <v-col cols="auto">
                <h5 class="text--disabled" v-if="!(totalPaid.toFixed(2) >= totalPrice.toFixed(2))">{{ $t("remining") }}
                </h5>
                <h5 class="text--disabled" v-if="(totalPaid.toFixed(2) >= totalPrice.toFixed(2))">{{ $t("more") }}</h5>
                <h5 :class="!(totalPaid.toFixed(2) >= totalPrice.toFixed(2)) ? 'error--text' : 'success--text'">
                  <strong>{{
                    $global.DecimalFormatter(Number(totalPrice.toFixed(2) - totalPaid.toFixed(2)).toFixed(2))
                  }}</strong>
                </h5>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pa-3 mt-2">
            <v-row justify="end" align="center">
              <v-col cols="auto">
                <GenericInput type="date" :dense="true" v-if="$store.state.userData.super_admin && isEdit"
                  :placeholder="'date'" :value="invoice_date" :clearable="false" @input="invoice_date = $event"
                  label="invoice date" :required="false" :hide-details="true" :isLoading="isLoading"
                  :cols="[12, 12, 12]" />
              </v-col>
              <v-col cols="auto">
                <GenericInput type="switch" :dense="true" color="blue5" :placeholder="'switch'" :value="is_special"
                  :clearable="false" @input="is_special = $event" label="special sales" :required="false"
                  :hide-details="true" :cols="[12, 12, 12]" />
              </v-col>
              <v-col cols="auto">
                <v-btn color="info" outlined @click="() => descriptionDialog = true" large
                  class="mx-1 white--text rounded-lg">
                  {{ $t('invoice description') }}
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn :disabled="bill.length == 0" :color="discount.discount_amount ? 'red6' : 'error'" depressed
                  @click="toggleDiscountDialog" large class="mx-1 white--text rounded-lg">
                  <v-icon size="18" class="d-none d-lg-block" left>mdi-sale</v-icon>
                  <span v-if="!discount.discount_amount">{{ $t('discount') }} {{ $t('?') }}</span>
                  <span v-if="discount.discount_amount">
                    {{ $t('edit discount') }}
                  </span>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn class="shadow" @click="resetPayment" large color="error" outlined>{{ $t("deletePaid") }}</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn class="shadow" :loading="loading" :disabled="!valid" @click="saveInvoice" large color="success">
                  {{ $t('save') }}</v-btn>
              </v-col>
            </v-row>
          </v-col>

        </v-col>

      </v-row>
      <!-- USED -->
      <UsedModal :vats="vats" :dialog="usedModal" :gold_price="gold_price" :backValueMethod="usedModalMethod" />
    </v-container>
    <!-- Modals -->
    <CustomerWithdrawals :closeDialog="closeWithdrawalsDialog" :withdrawalsDialog="withdrawalsDialog" />
    <PrintTemplatesModal :dialog="printDialog" :print_templates="print_templates" :backValueMethod="closePrint"
      :invoice_id="invoice_id" :has_whatsapp="has_whatsapp" />
    <DiscountModal :dialog="discountDialog" :discount="discount" :coupons="coupons" :setDiscount="setDiscount"
      :returnCloseDialog="() => toggleDiscountDialog" :customerType="customerType"
      :customer_data="customerType == 1 ? customer : supplier" />
    <CustomModal :dialogContent="{
      icon: '',
      title: 'invoice description',
      details: '',
      buttonOneTitle: 'done',
      buttonOneColor: 'primary',
      buttonTwoTitle: 'cancel',
      buttonTwoColor: 'error',
    }" :dialog="descriptionDialog" :buttonOneMethod="() => { descriptionDialog = false; }"
      @close="() => { descriptionDialog = false; description = '' }" :closeDialog="() => { descriptionDialog = false; }"
      :buttonTwoMethod="() => { descriptionDialog = false; }" :persistent="true">
      <template v-slot:content>
        <GenericInput type="textarea" :solo="true" :dense="true" placeholder="write here..." :value="description"
          :clearable="true" @input="description = $event" label="" :cols="[12, 12, 12]" />
      </template>
    </CustomModal>
  </section>
</template>


<script>
import { SalesQTYMixins } from '@/mixins/SalesQTYMixins.js';
import ItemModal from "@/components/modals/ItemModal.vue";
import DiamondModal from "@/components/modals/DiamondModal.vue";
import DefaultItemSalesModal from "@/components/modals/DefaultItemSalesModal.vue";
import SilverModal from "@/components/modals/SilverModal.vue";
import ServicesModal from "@/components/modals/ServicesModal.vue";
import UsedModal from "@/components/modals/UsedModal.vue";
import SellarModal from "@/components/modals/SellarModal.vue";
import PaymentMethod from "@/components/modals/PaymentMethod.vue";
import BankModal from "@/components/modals/BankModal.vue";
import InvoiceData from "@/components/InvoiceData";
import SalesByPiece from "@/components/SalesByPiece.vue";
import SkeletonInvoiceLoader from "@/components/SkeletonInvoiceLoader.vue";
import CustomerModal from "@/components/modals/CustomerModal.vue";
import CategoriesBar from "@/components/CategoriesBar.vue";
import PrintTemplatesModal from "@/components/modals/PrintTemplatesModal.vue";
import CustomerWithdrawals from "@/components/modals/CustomerWithdrawals.vue";
import CustomerDepositModal from "@/components/modals/CustomerDepositModal.vue";
import GenericInput from "@/components/GenericInput.vue";
import DiscountModal from '@/components/modals/DiscountModal.vue';
import CustomModal from '@/components/modals/CustomModal.vue';
export default {
  name: "SalesInvoicesQTY",
  mixins: [SalesQTYMixins],
  data: () => ({

  }),
  components: {
    ItemModal,
    DiamondModal,
    ServicesModal,
    DefaultItemSalesModal,
    UsedModal,
    InvoiceData,
    SalesByPiece,
    SellarModal,
    PaymentMethod,
    BankModal,
    CustomerModal,
    SkeletonInvoiceLoader,
    CategoriesBar,
    PrintTemplatesModal,
    CustomerWithdrawals,
    CustomerDepositModal,
    SilverModal,
    GenericInput,
    CustomModal,
    DiscountModal
  },

};
</script>

<style scoped lang="scss">
.paddingX {
  padding: 3px;
}
</style>