<template>
  <div class="text-center" id="appBarMenu">
    <v-menu :close-on-content-click="false" :nudge-width="200" offset-y transition="slide-x-transition" bottom right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn small v-bind="attrs" v-on="on" class="white" fab elevation="0">
          <img src="@/assets/img/svg/humborger.svg" alt="Qarat-POS" />
        </v-btn>
      </template>

      <v-card class="shadow">
        <v-list color="transparent">
          <v-list-item>
            <v-list-item-action>
              <v-row>
                <v-col cols="2" class="pb-0">
                  <v-icon color="#000">mdi-account-circle-outline </v-icon>
                </v-col>
                <v-col cols="10" class="pb-0">
                  <h5>{{ $store.state.userData.full_name }}</h5>
                  <p class="text--disabled mb-0">{{ $t("company number") }} : {{ $store.state.clientID }}</p>
                </v-col>
              </v-row>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-row>
                <v-col cols="2" class="pb-0">
                  <img src="@/assets/img/svg/Group 713.svg" alt="Qarat-POS" />
                </v-col>
                <v-col cols="10" class="pb-0">
                  <h5>{{ $t('startShift') }}</h5>
                  <p class="text--disabled mb-2">
                    {{ $store.state.lastUpdate | dateAr }}
                  </p>
                </v-col>
              </v-row>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="$store.state.lastUpdate">
            <v-list-item-action>
              <v-row>
                <v-col cols="2" class="py-0">
                  <img src="@/assets/img/svg/Group 715.svg" alt="Qarat-POS" />
                </v-col>
                <v-col cols="10" class="py-0">
                  <h5>{{ $t('lastUpdate') }}</h5>
                  <p class="text--disabled mb-0">
                    {{ $store.state.updated | dateAr }}
                  </p>
                </v-col>
              </v-row>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <div class="d-flex align-center">
                <v-col cols="2" class="py-0">
                  <img src="@/assets/img/svg/apartment.svg" class="mx-n2" alt="Qarat-POS" />
                </v-col>
                <v-col cols="12" class="py-0">
                  <h5>{{ $t('branch') }}</h5>
                  <p class="text--disabled my-0">
                    {{ $vuetify.rtl ? $store.state.userData.user.shift.name_ar :
                      $store.state.userData.user.shift.name_en
                    }}
                  </p>
                </v-col>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <div class="d-flex align-center">
                <v-col cols="2" class="py-0">
                  <img src="@/assets/img/png/ZatcaActive.png" height="20" class="mx-n2" alt="Qarat-POS" />
                </v-col>
                <v-col cols="12" class="py-0">
                  <h5>{{ $t(zatcaVersion) }}</h5>
                  <p v-if="zatcaVersion === 'V2'" class="text--disabled my-0">
                    {{ $t(zatcaToken) }}
                  </p>
                </v-col>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-btn @click="clearCacheAndReload" rounded class="bg-white" depressed>
                <img class="mx-1" height="25" src="@/assets/img/svg/reload.svg" alt="Qarat-POS" />
                <span class="">{{ $t('restart the program') }}</span>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-row align="center" justify="space-between">
                <v-col cols="4" md="12" class="d-lg-none d-block ">
                  <v-btn rounded class="bg-white" depressed to="/close-shift">
                    <img class="mx-1" src="@/assets/img/svg/logout.svg" alt="Qarat-POS" />
                    <span class="">{{ $t('endShift') }}</span>
                  </v-btn>
                </v-col>
                <v-col cols="auto" class="d-block ">
                  <v-btn small fab elevation="0" v-fullscreen.teleport="options">
                    <v-icon v-if="!fullscreen">mdi-fullscreen</v-icon>
                    <v-icon v-if="fullscreen">mdi-fullscreen-exit</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="auto" class="d-block ">
                  <v-btn v-on:click="changeLanguage" small fab class="bg-white d-block ma-auto" elevation="0">
                    <img src="@/assets/img/svg/USA.svg" height="25" v-if="$vuetify.rtl == true" alt="Qarat-POS" />
                    <img src="@/assets/img/svg/KSA.svg" height="25" v-if="$vuetify.rtl == false" alt="Qarat-POS" />
                  </v-btn>
                </v-col>
                <v-col cols="auto" class="">
                  <v-btn @click="logoutDialog = true" rounded class="bg-white" depressed>
                    <img class="mx-1" src="@/assets/img/svg/log.svg" alt="Qarat-POS" />
                    <span class="">{{ $t('logout') }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-action>
          </v-list-item>

          <v-list-item class="d-md-none" v-if="salesPermission == true">
            <v-btn to="/sales" active-class="primary" style="height: 30px" block small color="transparent" depressed>{{
              $t("sales")
            }}</v-btn>
          </v-list-item>

          <v-list-item class="d-md-none" v-if="purchasePermission == true">
            <v-btn to="/purchases" active-class="primary" block style="height: 30px" small color="transparent"
              depressed>{{
                $t("usedInvoice") }}</v-btn>
          </v-list-item>

          <v-list-item class="d-md-none" v-if="returnPermission == true">
            <v-btn to="/return-sales" active-class="primary" block style="height: 30px" small color="transparent"
              depressed>{{
                $t("return sales") }}</v-btn>
          </v-list-item>

          <v-list-item class="d-md-none" v-if="noticePermission">
            <v-btn to="/notice" active-class="primary" block style="height: 30px" small color="transparent" depressed>{{
              $t("notice") }}</v-btn>
          </v-list-item>


          <v-list-item class="d-md-none" v-if="expensesPermission == true">
            <v-btn to="/expenses" active-class="primary" block style="height: 30px" small color="transparent"
              depressed>{{
                $t("expenses") }}</v-btn>
          </v-list-item>

          <v-list-item class="d-md-none" v-if="depositPermission == true">
            <v-btn to="/deposits" active-class="primary" block style="height: 30px" small color="transparent"
              depressed>{{
                $t("deposits") }}</v-btn>
          </v-list-item>

          <v-list-item class="d-md-none" v-if="reportsPermission">
            <v-btn to="/reports" active-class="primary" block style="height: 30px" small color="transparent"
              depressed>{{
                $t("reports") }}</v-btn>
          </v-list-item>
          <v-list-item class="d-md-none" v-if="goldPermission">
            <v-btn to="/permission" active-class="primary" block style="height: 30px" small color="transparent"
              depressed>{{
                $t("gold permission") }}</v-btn>
          </v-list-item>

          <v-list-item class="d-md-none" v-if="transfersReportPermission == true">
            <v-btn to="/transfers" active-class="primary" block style="height: 30px" small color="transparent"
              depressed>{{
                $t("transfers") }}</v-btn>
          </v-list-item>

        </v-list>
        <div class="caption px-2 gray8--text text-end"> Version: {{ appName }}_{{ $store.state.app_version }}</div>
      </v-card>
    </v-menu>
    <LogoutConfirmation :dialog="logoutDialog" :backValueMethod="logout"></LogoutConfirmation>
  </div>
</template>
<style>
#appBarMenu .v-menu__content {
  box-shadow: none !important;
  top: 64px !important;
  box-shadow: 0px 4px 28px rgb(0 0 0 / 5%) !important;
}

#appBarMenu.v-menu__content .v-card {
  background-color: #fafafa !important;
}
</style>
<script>
import { UpdateSystem } from "@/mixins/UpdateSystem";
import LogoutConfirmation from "./modals/LogoutConfirmation.vue";
export default {
  name: "AppBarMenu",
  mixins: [UpdateSystem],
  computed: {
    salesPermission() {
      return this.$global.CheckAction("06-001", 1) || this.$global.CheckAction("06-008", 1)
    },
    purchasePermission() {
      return this.$global.CheckAction("06-100", 1)
    },
    returnPermission() {
      return this.$global.CheckAction("06-500", 1) || this.$global.CheckAction("06-500", 1)
    },
    noticePermission() {
      return (this.$global.CheckAction("06-003", 1) || this.$global.CheckAction("06-002", 1))
    },
    expensesPermission() {
      return this.$global.CheckAction("06-101", 1)
    },
    reportsPermission() {
      return (this.$global.CheckAction("06-014", 1) || this.$global.CheckAction("06-018", 1) || this.$global.CheckAction("02-020", 1))
    },
    transfersReportPermission() {
      return (this.$global.CheckAction("06-021", 1) || this.$global.CheckAction("06-022", 1))
    },
    goldPermission() {
      return (this.$global.CheckAction("02-014", 1) || this.$global.CheckAction("02-015", 1))
    },
    depositPermission() {
      return this.$global.CheckAction("06-023", 1)
    },
    options() {
      return {
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen
        },
        target: '.directive-fullscreen-wrapper',
        pageOnly: this.pageOnly,
        teleport: this.teleport
      }
    },
    zatcaVersion() {
      return this.$store.state?.userData?.zatca_app?.check ? 'V2' : 'V1';
    },
    zatcaToken() {
      return this.$store.state?.userData?.zatca_app?.env ? 'Core' : 'Simulation';
    },
  },
  components: {
    LogoutConfirmation
  },
  data() {
    return {
      logoutDialog: false,
      lastUpdate: this.$store.state.lastUpdate,
      fullscreen: false,
      appName: null
    };
  },
  mounted() { this.appName = process?.env?.VUE_APP_PROJECT },

  methods: {
    logout(status) {
      if (status == true) {
        this.$api.LOGOUT(true)
      }
      this.logoutDialog = false
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },

  },
};
</script>
